import { Config } from '../../interfaces/config.interface';
import Logo from './logo.png';

const config: Config = {
  name: 'Enlazando',
  title: 'Actividades de voluntariado',
  logo: Logo,
  logo_width: '220px',
  catalogTalents: true,
};

export default config;
